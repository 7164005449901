import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

const render = Component =>
  ReactDOM.render(<Component />, document.getElementById("root"));
render(App);

if (module.hot) {
  module.hot.accept("./components/App", () =>
    render(require("./components/App").default)
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
